/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.graphHeader,
.graph-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  align-items: center;
  justify-content: center;
}
.bodyMapDot {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.good {
  color: #26d87c;
}
.bad {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.errorScreen {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  overflow: auto;
}
.errorScreen:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../../_imgs/quino.jpg);
  background-size: cover;
  opacity: 0.1;
}
.errorScreen > div {
  margin: auto;
  max-width: 600px;
}
.errorScreen h1 {
  margin-bottom: 30px;
}
.errorScreen .stack {
  padding: 15px;
  line-height: 1.5em;
  font-size: 11px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.errorScreen .stack strong {
  margin-bottom: 10px;
}
.errorScreen .stack code {
  display: block;
  padding: 0;
  margin: 0;
  font-family: 'Fira Code', monospace;
  text-overflow: ellipsis;
  background: none;
  border: none;
  overflow: hidden;
}
.errorScreen.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  background-color: rgba(26, 29, 31, 0.9);
}
.errorScreen.overlay:before {
  content: none;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 0;
}
.footer button {
  margin-bottom: 0 !important;
}
.footer button:not(:last-child) {
  margin-right: 10px;
}
