/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.graphHeader,
.graph-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  align-items: center;
  justify-content: center;
}
.bodyMapDot {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.good {
  color: #26d87c;
}
.bad {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.wrap {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
}
@media (max-width: 1000px) {
  .wrap {
    flex-direction: column-reverse;
    height: auto;
    min-height: 100vh;
    font-size: 16px;
  }
  .wrap h3 {
    font-size: 30px;
    line-height: 1.25em;
  }
}
.form {
  position: relative;
  z-index: 2;
  width: 50vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  background-color: #222629;
  -webkit-transform: translateX(-50vw);
  transform: translateX(-50vw);
  transition: -webkit-transform 0.75s ease;
  transition: transform 0.75s ease;
  transition: transform 0.75s ease, -webkit-transform 0.75s ease;
}
@media (max-width: 1000px) {
  .form {
    width: 100vw;
    height: auto;
    min-height: calc(100vh - 200px);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.validSlug .form {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.form h3 {
  width: 100%;
  margin-bottom: 30px;
}
.form h3 + p {
  margin-top: -15px;
  margin-bottom: 30px;
}
.form form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 30px;
  width: 100%;
}
@media (max-width: 1000px) {
  .form form {
    align-items: flex-start;
    margin-top: -67.5px;
  }
}
.form footer {
  font-size: 12px;
}
.form footer span {
  display: inline-block;
  padding: 0 10px;
}
.form footer a {
  color: #8a96a0;
  text-decoration: none;
}
.form footer a:hover {
  text-decoration: underline;
}
.form-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}
.form-wrap > *:not(:last-child, svg) {
  margin-bottom: 1.875rem;
}
.form-wrap > div {
  width: 100%;
}
.form-wrap > svg {
  margin-bottom: 60px;
}
.form-wrap label {
  width: 100%;
}
.form-wrap button {
  width: 100%;
  margin: 0 0 30px;
}
.notice {
  width: 100%;
  margin: 0 0 30px;
}
.img {
  position: relative;
  z-index: 1;
  width: 50vw;
  height: 100vh;
}
@media (max-width: 1000px) {
  .img {
    width: 100vw;
    height: 200px;
  }
}
.img img {
  width: 100vw;
  height: 100%;
  opacity: 0.3 !important;
  object-fit: cover;
  pointer-events: none;
  -webkit-transform: translateX(-50vw);
  transform: translateX(-50vw);
  transition: -webkit-transform 0.75s ease !important;
  transition: transform 0.75s ease !important;
  transition: transform 0.75s ease, -webkit-transform 0.75s ease !important;
}
.validSlug .img img {
  -webkit-transform: translateX(-25vw);
  transform: translateX(-25vw);
}
@media (max-width: 1000px) {
  .img img {
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }
}
.input {
  width: 100%;
  margin-bottom: 15px;
  padding: 0 0 0 14px;
  border: 1px solid #41494f;
  border-radius: 4px;
}
.why {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.why button {
  width: auto;
  margin: 0;
  opacity: 0.25;
}
.why button span {
  text-decoration: none;
}
