/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.graphHeader,
.graph-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  align-items: center;
  justify-content: center;
}
.bodyMapDot {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.good {
  color: #26d87c;
}
.bad {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
body:global(.fixedHeight),
body:global(.fixedHeight #root),
body:global(.fixedHeight #root) main {
  height: 100%;
  overflow: hidden;
  /* safari x scroll */
}
body:global(.smallSidebar) .sidebar {
  -webkit-transform: translateX(calc(-100% +  80px));
  transform: translateX(calc(-100% +  80px));
}
body:global(.smallSidebar) .nav {
  -webkit-transform: translateX(calc(100% -  80px - 0.5rem));
  transform: translateX(calc(100% -  80px - 0.5rem));
}
body:global(.smallSidebar) .nav a span {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}
body:global(.smallSidebar) .version {
  display: none;
}
body:global(.smallSidebar) .logout {
  -webkit-transform: translateX(calc(100% - 80px - 30px / 2));
  transform: translateX(calc(100% - 80px - 30px / 2));
}
body:global(.smallSidebar) .logout span {
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}
body:global(.smallSidebar) .logout svg {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}
body:global(.smallSidebar) .body {
  margin-left: 80px;
}
.sidebar {
  position: fixed;
  z-index: 20;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 100vh;
  background-color: #1a1d1f;
  overflow: auto;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
@media (max-width: 1367px) {
  .sidebar {
    -webkit-transform: translateX(calc(-100% +  80px));
    transform: translateX(calc(-100% +  80px));
  }
}
@media print {
  .sidebar {
    display: none;
    transition: none;
  }
}
.sidebar.super {
  background-color: #41494f;
  border-right-color: #8a96a0;
}
.logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100px + 30px*2);
  padding: 30px;
}
.logo img {
  max-width: 80px;
  height: auto;
}
.nav {
  flex-grow: 1;
  padding: 0 1rem 1rem;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
@media (max-width: 1367px) {
  .nav {
    -webkit-transform: translateX(calc(100% -  80px - 0.5rem));
    transform: translateX(calc(100% -  80px - 0.5rem));
  }
}
.nav a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  margin: 0 0 10px;
  padding: 1.1rem;
  color: #8a96a0;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 20px;
  transition: color 0.15s ease, background-color 0.15s ease;
}
.nav a svg {
  width: 20px;
  vertical-align: middle;
  margin-right: 15px;
}
.nav a svg line,
.nav a svg path,
.nav a svg polyline,
.nav a svg polygon,
.nav a svg rect {
  stroke: #8a96a0;
  transition: stroke 0.15s ease;
}
.nav a span {
  flex-grow: 1;
  color: inherit;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
@media (max-width: 1367px) {
  .nav a span {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
}
.nav a i {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #f8c81c;
  border-radius: 50%;
}
.nav a.active,
.nav a:hover {
  color: #fff;
}
.nav a.active svg line,
.nav a:hover svg line,
.nav a.active svg path,
.nav a:hover svg path,
.nav a.active svg polyline,
.nav a:hover svg polyline,
.nav a.active svg polygon,
.nav a:hover svg polygon,
.nav a.active svg rect,
.nav a:hover svg rect {
  stroke: #fff;
}
.nav a.active {
  background-color: #485056;
}
.version {
  padding: 15px 30px;
  color: rgba(138, 150, 160, 0.5);
  font-size: 11px;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.version button {
  padding: 0;
  margin: 0 0 0 10px;
  color: red;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
}
@media (max-width: 1367px) {
  .version {
    display: none;
  }
}
.logout {
  padding: 30px;
  color: #8a96a0;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  text-align: left;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-top: 1px solid #41494f;
  cursor: pointer;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
@media (max-width: 1367px) {
  .logout {
    -webkit-transform: translateX(calc(100% - 80px - 30px / 2));
    transform: translateX(calc(100% - 80px - 30px / 2));
  }
}
.super .logout {
  border-top-color: #485056;
}
.logout span {
  display: inline-block;
  transition: color 0.15s ease, -webkit-transform 0.5s ease;
  transition: color 0.15s ease, transform 0.5s ease;
  transition: color 0.15s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
}
@media (max-width: 1367px) {
  .logout span {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}
.logout svg {
  width: 20px;
  vertical-align: middle;
  margin-right: 15px;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.logout svg line,
.logout svg path,
.logout svg polyline,
.logout svg polygon,
.logout svg rect {
  stroke: #8a96a0;
  transition: stroke 0.15s ease;
}
@media (max-width: 1367px) {
  .logout svg {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
}
.logout.active span,
.logout:hover span {
  color: #fff;
}
.logout.active svg line,
.logout:hover svg line,
.logout.active svg path,
.logout:hover svg path,
.logout.active svg polyline,
.logout:hover svg polyline,
.logout.active svg polygon,
.logout:hover svg polygon,
.logout.active svg rect,
.logout:hover svg rect {
  stroke: #fff;
}
.body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-left: 240px;
  transition: margin-left 0.5s ease;
}
@media print {
  .body {
    margin-left: 0 !important;
    transition: none;
  }
}
@media (max-width: 1367px) {
  .body {
    margin-left: 80px;
  }
}
