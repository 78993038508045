@font-face {
  font-family: 'SF UI Text';
  src: url('./_fonts/hinted-SFUIText-Medium.woff2') format('woff2'),
    url('./_fonts/hinted-SFUIText-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./_fonts/hinted-SFUIText-BoldItalic.woff2') format('woff2'),
    url('./_fonts/hinted-SFUIText-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./_fonts/hinted-SFUIText-Regular.woff2') format('woff2'),
    url('./_fonts/hinted-SFUIText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./_fonts/hinted-SFUIText-RegularItalic.woff2') format('woff2'),
    url('./_fonts/hinted-SFUIText-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./_fonts/hinted-SFUIText-MediumItalic.woff2') format('woff2'),
    url('./_fonts/hinted-SFUIText-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./_fonts/hinted-SFUIText-Bold.woff2') format('woff2'),
    url('./_fonts/hinted-SFUIText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Text';
  src: url('./_fonts/hinted-SFUIText-Heavy.woff2') format('woff2'),
    url('./_fonts/hinted-SFUIText-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@import 'variables';
@import '_less/reset';

* {
  margin: 0;
  padding: 0;

  font-family: @font-family;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;

  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  position: relative;
  min-height: 100%;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;

  color: @text-color;
  font-size: 14px;
  font-weight: 100;

  background-color: @background-border-medium;

  -webkit-user-select: none;

          user-select: none;

  @media print {
    color: @background-border-dark;
    background-color: transparent;
  }

  &.ReactModal__Body--open {
    overflow: hidden;
  }
}

::selection {
  background: @selection;
}

::-moz-selection {
  background: @selection;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: @secondary;
}

h1,
.h1,
h2,
h3 {
  color: #fff;
  font-weight: bold;

  @media print {
    color: @background-border-dark;
  }
}

h1,
.h1 {
  font-size: 40px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
  font-weight: bold;
}

form > *:not(:last-child) {
  margin-bottom: 1rem;
}

.w {
  color: #fff;
}

h5,
.h5 {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

h6 {
  margin: @spacer 0 10px;

  font-size: 11px;
  font-weight: 100;
  text-transform: uppercase;
}

h2,
h3,
h4,
h5 {
  & + p {
    margin-top: @spacer / 2;
  }
}

ul {
  list-style: none;
}

p {
  line-height: 1.7em;

  &:not(:last-child) {
    margin-bottom: @spacer / 2;
  }
}

a {
  color: #fff;

  transition: color 0.15s ease;

  &:hover {
    color: @text-color;
  }
}

i {
  font-style: normal;
}

strong {
  color: inherit;
  font-weight: bold;
}

video::-webkit-media-controls {
  display: none !important;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}

code {
  display: block;
  margin: 5px 0;
  padding: 7px;

  font-family: 'Fira Code', monospace;

  background-color: rgba(255, 255, 255, 0.05);
  border: 2px dashed @warning;
  border-radius: 3px;

  &.nice {
    border: none;
  }
}

hr {
  margin: @spacer 0;

  border: none;
  border-bottom: 1px solid @background-border-light;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

// Global Classes
// =========================================================================

// hidden element
.hidden {
  position: absolute;
  width: 0;
  height: 0;
  border: 0;
  top: -1px;
  left: -1px;
}

// unstyled button
button.unstyled {
  background-color: transparent;
  color: @secondary;
  border: none;
  text-align: left;
}

// number input without spinner
.nospin input[type='number']::-webkit-outer-spin-button,
.nospin input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
          appearance: none;
  margin: 0;
}

.nospin input[type='number'] {
  -webkit-appearance: textfield;
          appearance: textfield;
}

input[type='number'].nospin::-webkit-outer-spin-button,
input[type='number'].nospin::-webkit-inner-spin-button {
  -webkit-appearance: none;
          appearance: none;
  margin: 0;
}

input[type='number'].nospin {
  -webkit-appearance: textfield;
          appearance: textfield;
}

// overflow hidden
.noscroll {
  overflow: hidden;
}

.opacity-0 {
  opacity: 0;
}

// Modals
// =========================================================================

.ReactModal__Overlay {
  z-index: @z-modal;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  -webkit-perspective: 1600px;
          perspective: 1600px;

  > div {
    transition: -webkit-transform 0.15s ease;
    transition: transform 0.15s ease;
    transition: transform 0.15s ease, -webkit-transform 0.15s ease;
    //transform: scale(0.95);
  }

  &--after-open {
    opacity: 1;

    /* Had to disable this so dnd will play nice inside modals :(
		 * https://github.com/atlassian/react-beautiful-dnd#warning-position-fixed
		 */
    /*> div {
			transform: scale(1);
		}*/
  }

  &--before-close {
    opacity: 0;

    > div {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
    }
  }
}

// Charts
// =========================================================================

.recharts-text {
  fill: @secondary;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.recharts-tooltip-cursor,
.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  stroke: @background-border-light;
}

.recharts-cartesian-grid line {
  stroke: @background-border-light;
}

.recharts-cartesian-axis > .recharts-text {
  fill: @secondary;
  text-anchor: middle;
}

.recharts-polar-angle-axis .recharts-text {
  fill: @secondary;
}

// Select
// =========================================================================

.rsel {
  &__control {
    min-height: 39px !important;

    background-color: transparent !important;
    border-color: @background-border-light !important;

    transition: border-color 0.15s ease, opacity 0.15s ease !important;
    border-radius: @round !important;

    input {
      color: @text-color !important;
    }

    &:hover {
      border-color: @text-color !important;
    }

    & + div {
      position: relative;
      z-index: 2;
    }

    &--is-disabled {
      opacity: 0.5;
    }

    &--is-focused {
      &,
      &:hover {
        border-color: #fff !important;
        box-shadow: none !important;
      }
    }
  }

  &__placeholder {
    color: fade(@text-color, 30%) !important;
  }

  &__indicator {
    &:hover {
      color: @text-color !important;
    }

    &-separator {
      background-color: @background-border-light !important;
    }
  }

  &__single-value {
    padding: 1px 0;
    color: #fff !important;
  }

  &__menu {
    position: absolute !important;
    z-index: @z-modal;

    background-color: @background-border-dark !important;
    border: 1px solid @background-border-light !important;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  }

  &__option {
    transition: background-color 0.15s ease;

    &:hover,
    &--is-focused {
      background-color: fade(@background-border-light, 25%) !important;
    }

    &--is-selected {
      &,
      &:hover {
        color: #fff !important;
        background-color: @background-border-light !important;

        & * {
          color: #fff !important;
        }
      }
    }
  }

  &__multi-value__remove:hover {
    background-color: @danger !important;

    path {
      fill: #fff;
    }
  }

  &__multi-value {
    padding: 4px;
    font-size: 16px;
  }

  &__value-container--is-multi {
    padding: 2px !important;

    input {
      margin-left: 8px;
    }

    .rsel__placeholder {
      margin-left: 8px !important;
    }
  }
}

// react-big-calendar
// =========================================================================

.rbc-calendar {
  height: 100%;

  .rbc-time-header {
    background-color: @background-border-medium;
    z-index: 3;

    .rbc-time-header-gutter {
      border-bottom: 1px solid @background-border-light;
    }
  }

  .rbc-time-gutter.rbc-time-column {
    z-index: 3;

    .rbc-timeslot-wrapper .menu,
    .rbc-timeslot-wrapper .rbc-timeslot-create {
      display: none;
    }
  }

  .rbc-allday-cell {
    display: none;
  }

  .rbc-current-time-indicator {
    background-color: @primary;
    width: 700%;
  }

  .rbc-timeslot-group {
    min-height: 77px;
  }

  .rbc-timeslot-group {
    .rbc-label {
      display: block;
      background-color: @background-border-medium;
      font-size: 11px;
      padding: 0 7.5px;
    }

    &:not(:first-child) .rbc-label {
      -webkit-transform: translateY(-7px);
              transform: translateY(-7px);
    }
  }

  .rbc-time-header-content {
    border-left: 0;
  }

  .rbc-header,
  .rbc-header + .rbc-header {
    border-color: @background-border-light;
  }

  .rbc-header:first-child {
    border-left: 1px solid transparent;
  }

  .rbc-header + .rbc-header {
    border-image: linear-gradient(
        to top,
        @background-border-light,
        @background-border-medium
      )
      1 1%;
  }

  .rbc-header {
    padding: 1rem;
    text-align: left;

    button {
      text-transform: uppercase;
      letter-spacing: 0.46px;

      strong {
        display: block;
        text-align: left;
        color: white;
        margin-top: 0.2rem;
        font-size: 1.5rem;
      }
    }
  }

  .rbc-time-header.rbc-overflowing {
    border-right: 0;
  }

  .rbc-day-slot.rbc-time-column:nth-child(2) {
    .rbc-events-container,
    .rbc-timeslot-group {
      border-left: 0;
    }
  }

  .rbc-day-slot .rbc-events-container {
    margin-right: 0;
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: 0;
  }

  .rbc-time-view {
    height: 100%;
    border: none;
  }

  .rbc-time-content {
    border-top: 0;
    overflow-x: hidden;
  }

  .rbc-timeslot-group {
    border-bottom: 1px solid fade(@background-border-light, 30%);
    position: relative;
  }

  .rbc-time-content > * + * > * {
    border-left: 1px solid @background-border-light;
  }

  .rbc-today {
    background-color: fade(@primary, 5%);

    &.rbc-header * {
      color: @primary;
    }
  }

  .rbc-day-slot .rbc-event {
    border: 0;
    border-left: 4px solid;
    z-index: 2;
  }

  .rbc-event-wrapper .rbc-event,
  .rbc-event.rbc-addons-dnd-drag-preview {
    padding: 0.5rem;
    gap: 0.2rem;

    strong {
      display: block;
      margin-bottom: 0.2rem;
    }

    * {
      font-size: 12px;
    }
  }

  .rbc-event-wrapper .rbc-event-label {
    margin-bottom: 0.2rem;
  }

  .rbc-event-wrapper .rbc-event-content svg {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;

    path {
      fill: currentColor;
    }
  }

  &.rbc-addons-dnd-is-dragging .rbc-event-wrapper.dragging .rbc-event {
    opacity: 0 !important;
  }

  .rbc-event-wrapper.workout .rbc-event,
  &.rbc-addons-dnd-is-dragging.workout .rbc-event.rbc-addons-dnd-drag-preview {
    background-color: fade(@workout, 30%);
    border-color: @workout;
    color: @workout;
  }
  .rbc-event-wrapper.competition .rbc-event,
  &.rbc-addons-dnd-is-dragging.competition
    .rbc-event.rbc-addons-dnd-drag-preview {
    background-color: fade(@game, 30%);
    border-color: @game;
    color: @game;
  }
  .rbc-event-wrapper.training .rbc-event,
  &.rbc-addons-dnd-is-dragging.training .rbc-event.rbc-addons-dnd-drag-preview {
    background-color: fade(@training, 30%);
    border-color: @training;
    color: @training;
  }
  .rbc-event-wrapper.meal .rbc-event,
  &.rbc-addons-dnd-is-dragging.meal .rbc-event.rbc-addons-dnd-drag-preview {
    background-color: fade(@meal, 30%);
    border-color: @meal;
    color: @meal;
  }
  .rbc-event-wrapper.meeting .rbc-event,
  &.rbc-addons-dnd-is-dragging.meeting .rbc-event.rbc-addons-dnd-drag-preview {
    background-color: fade(@appointment, 30%);
    border-color: @appointment;
    color: @appointment;
  }
  .rbc-event-wrapper.meeting2 .rbc-event,
  &.rbc-addons-dnd-is-dragging.meeting2 .rbc-event.rbc-addons-dnd-drag-preview {
    background-color: fade(@meeting2, 30%);
    border-color: @meeting2;
    color: @meeting2;
  }
  .rbc-event-wrapper.travel .rbc-event,
  &.rbc-addons-dnd-is-dragging.travel .rbc-event.rbc-addons-dnd-drag-preview {
    background-color: fade(@travel, 30%);
    border-color: @travel;
    color: @travel;
  }
}
