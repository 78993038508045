/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.graphHeader,
.graph-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  align-items: center;
  justify-content: center;
}
.bodyMapDot {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.good {
  color: #26d87c;
}
.bad {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.15s ease;
}
.button svg:first-child {
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 10px;
}
.button span {
  position: static;
  color: #222629 !important;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  transition: opacity 0.15s ease;
}
.button:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  border: 3px solid #f8c81c;
  border-top-color: transparent !important;
  border-radius: 50%;
  opacity: 0;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  transition: opacity 0.15s ease;
}
.button:hover {
  text-decoration: none;
  opacity: 0.5;
}
.button:hover span {
  text-decoration: none;
}
.button:disabled {
  opacity: 0.25;
  pointer-events: none;
}
.primary {
  background-color: #fff;
}
.primary:after {
  border-color: #222629;
}
.secondary {
  background-color: #8a96a0;
}
.secondary:after {
  border-color: #fff;
}
.tertiary {
  background-color: #1a1d1f;
}
.tertiary span {
  color: #8a96a0 !important;
}
.quaternary {
  background-color: #41494f;
}
.quaternary:hover {
  opacity: 1 !important;
  background-color: #8a96a0;
}
.brand {
  background-color: #f8c81c;
}
.brand:after {
  border-color: #fff;
}
.dashed {
  background: none;
  border: 2px dashed #41494f;
  border-radius: 5px;
}
.dashed span {
  color: #41494f !important;
  font-size: 15px;
  font-weight: bold;
  text-transform: none;
}
.dashed:after {
  border-color: #41494f;
}
.danger {
  background: rgba(238, 23, 55, 0.3);
  border: none;
  border-radius: 5px;
}
.danger span {
  color: #ee1737 !important;
}
.danger:after {
  border-color: #ee1737;
}
.info {
  background: none;
  border: 1px solid #2196f3;
  border-radius: 5px;
}
.info span {
  color: #2196f3 !important;
}
.info:after {
  border-color: #2196f3;
}
.success {
  background: #26d87c;
  border: 1px solid #26d87c;
  border-radius: 5px;
}
.success span {
  color: #222629 !important;
}
.success:after {
  border-color: #26d87c;
}
.danger-small {
  background: none;
  border: none;
}
.danger-small span {
  color: #ee1737 !important;
  font-size: 12px;
  text-transform: none;
}
.danger-small:after {
  border-color: #ee1737;
}
.none {
  background: none;
  border-radius: 0;
}
.text {
  padding: 0 !important;
  background: none;
  border: none;
  border-radius: 0;
}
.text span {
  color: #fff !important;
  font-weight: normal;
  text-transform: none;
  text-decoration: underline !important;
}
.outline {
  background: none;
  border: 1px solid #41494f;
}
.outline span {
  color: #8a96a0 !important;
  font-weight: normal;
}
.small {
  padding: 5px 10px 4px;
}
.small:after {
  width: 8px;
  height: 8px;
}
.small.danger {
  padding: 4px 10px 3px;
}
.small span {
  font-size: 13px;
}
.large {
  padding: 11px 30px;
}
.large.danger {
  padding: 9px 15px;
}
.huge {
  width: 100%;
  padding: 20px;
}
.huge:after {
  width: 16px;
  height: 16px;
}
.huge.danger {
  padding: 19px 20px;
}
.wide {
  width: 100%;
}
.busy {
  pointer-events: none;
  tab-index: 0;
}
.busy span {
  opacity: 0;
}
.busy:after {
  opacity: 1;
  -webkit-animation: spinCentre 1s linear infinite;
  animation: spinCentre 1s linear infinite;
}
