/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.graphHeader,
.graph-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  align-items: center;
  justify-content: center;
}
.bodyMapDot {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.good {
  color: #26d87c;
}
.bad {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
:global(.smallSidebar) .feed-btn {
  -webkit-transform: translate3d(65px, -50%, 0);
  transform: translate3d(65px, -50%, 0);
}
.feed-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: translate3d(68px, -50%, 0);
  transform: translate3d(68px, -50%, 0);
}
.feed-btn svg {
  pointer-events: none;
}
@media (max-width: 1367px) {
  .feed-btn {
    -webkit-transform: translate3d(65px, -50%, 0);
    transform: translate3d(65px, -50%, 0);
  }
}
.overlay {
  position: fixed;
  z-index: 25;
  top: 0;
  left: 240px;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 38, 41, 0.8);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s ease;
}
@media (max-width: 1367px) {
  .overlay {
    left: 80px;
  }
}
.overlayOpen {
  opacity: 1;
}
.overlayClose {
  opacity: 0;
  transition-delay: 0.1s;
}
.modal {
  width: 100%;
  max-width: 650px;
  height: 100vh;
  inset: unset;
  padding: 30px;
  background-color: #222629;
  border: none;
  border-right: 1px solid #41494f;
  border-radius: 0;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  opacity: 1;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  overflow: auto;
}
.modal h3 {
  margin-bottom: 30px;
}
.modalOpen {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.modalClose {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
