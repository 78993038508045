/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.graphHeader,
.graph-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  align-items: center;
  justify-content: center;
}
.bodyMapDot {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.good {
  color: #26d87c;
}
.bad {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.errorList {
  display: block;
  padding: 15px;
  list-style: none;
  color: #ee1737;
  line-height: 1.5em;
  background-color: rgba(238, 23, 55, 0.05);
  border: 1px solid #c40e29;
  border-radius: 5px;
}
.errorList:not(:last-child) {
  margin-bottom: 30px;
}
.errorList li:not(:last-child) {
  margin-bottom: 10px;
}
