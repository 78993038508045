/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.graphHeader,
.graph-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  align-items: center;
  justify-content: center;
}
.bodyMapDot {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.good {
  color: #26d87c;
}
.bad {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.switchBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: translate3d(-100px, -50%, 0);
  transform: translate3d(-100px, -50%, 0);
}
.switchBtn svg {
  pointer-events: none;
}
@media (max-width: 1367px) {
  .switchBtn {
    -webkit-transform: translate3d(95px, -175%, 0);
    transform: translate3d(95px, -175%, 0);
  }
}
.overlay {
  position: fixed;
  z-index: 25;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(26, 29, 31, 0.8);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.overlayOpen {
  opacity: 1;
}
.overlayClose {
  opacity: 0;
  transition-delay: 0.1s;
}
.modal {
  width: 100%;
  max-width: 960px;
  max-height: 100vh;
  inset: unset;
  margin: 0 auto;
  padding: 100px 30px;
  background: none;
  border: none;
  opacity: 1;
  overflow: auto;
}
.modal h3 {
  margin-bottom: 30px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}
.header input {
  min-width: 50%;
  padding: 11px 0;
  color: #fff;
  text-indent: 16px;
  -webkit-appearance: none;
  appearance: none;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 8px;
  outline: none;
}
.header input::-webkit-input-placeholder {
  color: rgba(138, 150, 160, 0.5);
}
.header input::placeholder {
  color: rgba(138, 150, 160, 0.5);
}
.tabs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: 3px;
  background: #1a1d1f;
  border-radius: 8px;
}
.tabs::before {
  content: '';
  position: absolute;
  z-index: 0;
  top: 3px;
  left: 3px;
  width: 115px;
  height: calc(100% - 6px);
  background: #8a96a0;
  border-radius: 4px;
  transition: -webkit-transform 0.15s ease;
  transition: transform 0.15s ease;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease;
}
.tabs.flip::before {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.tabs.flip button:first-child {
  color: #8a96a0;
}
.tabs.flip button:last-child {
  color: #1a1d1f;
}
.tabs button {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  padding: 10px 0;
  color: #8a96a0;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.15s ease;
}
.tabs button:first-child {
  color: #1a1d1f;
}
.orgs {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 50px;
  list-style: none;
}
.orgs li button,
.orgs li a {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #8a96a0;
  font-size: 13px;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
}
.orgs li button span,
.orgs li a span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  color: #f8c81c;
  font-size: 54px;
  font-weight: bold;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
}
.orgs li button img,
.orgs li a img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.orgs li button svg,
.orgs li a svg {
  vertical-align: middle;
}
.orgs li button small,
.orgs li a small {
  display: block;
  margin-top: 5px;
  font-size: 11px;
  opacity: 0.5;
}
.current {
  opacity: 0.5;
  pointer-events: none;
}
