@font-face {
  font-family: 'SF UI Text';
  src: url(/static/media/hinted-SFUIText-Medium.0d86a195.woff2) format('woff2'), url(/static/media/hinted-SFUIText-Medium.821fc0a4.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'SF UI Text';
  src: url(/static/media/hinted-SFUIText-BoldItalic.5f0b0560.woff2) format('woff2'), url(/static/media/hinted-SFUIText-BoldItalic.9ba75c9f.woff) format('woff');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'SF UI Text';
  src: url(/static/media/hinted-SFUIText-Regular.c21d8b0f.woff2) format('woff2'), url(/static/media/hinted-SFUIText-Regular.a3961e32.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SF UI Text';
  src: url(/static/media/hinted-SFUIText-RegularItalic.46c690ce.woff2) format('woff2'), url(/static/media/hinted-SFUIText-RegularItalic.6a283166.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'SF UI Text';
  src: url(/static/media/hinted-SFUIText-MediumItalic.9f437e6b.woff2) format('woff2'), url(/static/media/hinted-SFUIText-MediumItalic.d810b485.woff) format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'SF UI Text';
  src: url(/static/media/hinted-SFUIText-Bold.574e639f.woff2) format('woff2'), url(/static/media/hinted-SFUIText-Bold.6187952e.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'SF UI Text';
  src: url(/static/media/hinted-SFUIText-Heavy.b65928e3.woff2) format('woff2'), url(/static/media/hinted-SFUIText-Heavy.b3bfba8d.woff) format('woff');
  font-weight: 900;
  font-style: normal;
}
/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.container {
  padding: 0 30px 30px;
}
.containerAll {
  padding: 30px;
}
.containerTb {
  padding: 30px 0;
}
.containerLr {
  margin: 0 30px;
}
.containerRbl {
  margin: 0 30px 30px;
}
.textCenter {
  text-align: center;
}
.flex {
  display: flex;
}
.well {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.well:not(:last-child) {
  margin-bottom: 10px;
}
.white {
  color: #fff;
}
.error {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.graph {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.graphHeader,
.graph-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.buttonGroup {
  display: flex;
}
.buttonGroup button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.center {
  align-items: center;
  justify-content: center;
}
.bodyMapDot {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.good {
  color: #26d87c;
}
.bad {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}
* {
  margin: 0;
  padding: 0;
  font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  position: relative;
  min-height: 100%;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  color: #8a96a0;
  font-size: 14px;
  font-weight: 100;
  background-color: #222629;
  -webkit-user-select: none;
  user-select: none;
}
@media print {
  body {
    color: #1a1d1f;
    background-color: transparent;
  }
}
body.ReactModal__Body--open {
  overflow: hidden;
}
::selection {
  background: rgba(138, 150, 160, 0.5);
}
::-moz-selection {
  background: rgba(138, 150, 160, 0.5);
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #8a96a0;
}
h1,
.h1,
h2,
h3 {
  color: #fff;
  font-weight: bold;
}
@media print {
  h1,
  .h1,
  h2,
  h3 {
    color: #1a1d1f;
  }
}
h1,
.h1 {
  font-size: 40px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 20px;
}
h4 {
  font-size: 18px;
  font-weight: bold;
}
form > *:not(:last-child) {
  margin-bottom: 1rem;
}
.w {
  color: #fff;
}
h5,
.h5 {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}
h6 {
  margin: 30px 0 10px;
  font-size: 11px;
  font-weight: 100;
  text-transform: uppercase;
}
h2 + p,
h3 + p,
h4 + p,
h5 + p {
  margin-top: 15px;
}
ul {
  list-style: none;
}
p {
  line-height: 1.7em;
}
p:not(:last-child) {
  margin-bottom: 15px;
}
a {
  color: #fff;
  transition: color 0.15s ease;
}
a:hover {
  color: #8a96a0;
}
i {
  font-style: normal;
}
strong {
  color: inherit;
  font-weight: bold;
}
video::-webkit-media-controls {
  display: none !important;
}
video::-webkit-media-controls-enclosure {
  display: none !important;
}
code {
  display: block;
  margin: 5px 0;
  padding: 7px;
  font-family: 'Fira Code', monospace;
  background-color: rgba(255, 255, 255, 0.05);
  border: 2px dashed #f8c81c;
  border-radius: 3px;
}
code.nice {
  border: none;
}
hr {
  margin: 30px 0;
  border: none;
  border-bottom: 1px solid #41494f;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
.hidden {
  position: absolute;
  width: 0;
  height: 0;
  border: 0;
  top: -1px;
  left: -1px;
}
button.unstyled {
  background-color: transparent;
  color: #8a96a0;
  border: none;
  text-align: left;
}
.nospin input[type='number']::-webkit-outer-spin-button,
.nospin input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.nospin input[type='number'] {
  -webkit-appearance: textfield;
  appearance: textfield;
}
input[type='number'].nospin::-webkit-outer-spin-button,
input[type='number'].nospin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type='number'].nospin {
  -webkit-appearance: textfield;
  appearance: textfield;
}
.noscroll {
  overflow: hidden;
}
.opacity-0 {
  opacity: 0;
}
.ReactModal__Overlay {
  z-index: 25;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  -webkit-perspective: 1600px;
  perspective: 1600px;
}
.ReactModal__Overlay > div {
  transition: -webkit-transform 0.15s ease;
  transition: transform 0.15s ease;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
  /* Had to disable this so dnd will play nice inside modals :(
		 * https://github.com/atlassian/react-beautiful-dnd#warning-position-fixed
		 */
  /*> div {
			transform: scale(1);
		}*/
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Overlay--before-close > div {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
.recharts-text {
  fill: #8a96a0;
}
.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}
.recharts-tooltip-cursor,
.recharts-cartesian-axis-line,
.recharts-cartesian-axis-tick-line {
  stroke: #41494f;
}
.recharts-cartesian-grid line {
  stroke: #41494f;
}
.recharts-cartesian-axis > .recharts-text {
  fill: #8a96a0;
  text-anchor: middle;
}
.recharts-polar-angle-axis .recharts-text {
  fill: #8a96a0;
}
.rsel__control {
  min-height: 39px !important;
  background-color: transparent !important;
  border-color: #41494f !important;
  transition: border-color 0.15s ease, opacity 0.15s ease !important;
  border-radius: 8px !important;
}
.rsel__control input {
  color: #8a96a0 !important;
}
.rsel__control:hover {
  border-color: #8a96a0 !important;
}
.rsel__control + div {
  position: relative;
  z-index: 2;
}
.rsel__control--is-disabled {
  opacity: 0.5;
}
.rsel__control--is-focused,
.rsel__control--is-focused:hover {
  border-color: #fff !important;
  box-shadow: none !important;
}
.rsel__placeholder {
  color: rgba(138, 150, 160, 0.3) !important;
}
.rsel__indicator:hover {
  color: #8a96a0 !important;
}
.rsel__indicator-separator {
  background-color: #41494f !important;
}
.rsel__single-value {
  padding: 1px 0;
  color: #fff !important;
}
.rsel__menu {
  position: absolute !important;
  z-index: 25;
  background-color: #1a1d1f !important;
  border: 1px solid #41494f !important;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.rsel__option {
  transition: background-color 0.15s ease;
}
.rsel__option:hover,
.rsel__option--is-focused {
  background-color: rgba(65, 73, 79, 0.25) !important;
}
.rsel__option--is-selected,
.rsel__option--is-selected:hover {
  color: #fff !important;
  background-color: #41494f !important;
}
.rsel__option--is-selected *,
.rsel__option--is-selected:hover * {
  color: #fff !important;
}
.rsel__multi-value__remove:hover {
  background-color: #ee1737 !important;
}
.rsel__multi-value__remove:hover path {
  fill: #fff;
}
.rsel__multi-value {
  padding: 4px;
  font-size: 16px;
}
.rsel__value-container--is-multi {
  padding: 2px !important;
}
.rsel__value-container--is-multi input {
  margin-left: 8px;
}
.rsel__value-container--is-multi .rsel__placeholder {
  margin-left: 8px !important;
}
.rbc-calendar {
  height: 100%;
}
.rbc-calendar .rbc-time-header {
  background-color: #222629;
  z-index: 3;
}
.rbc-calendar .rbc-time-header .rbc-time-header-gutter {
  border-bottom: 1px solid #41494f;
}
.rbc-calendar .rbc-time-gutter.rbc-time-column {
  z-index: 3;
}
.rbc-calendar .rbc-time-gutter.rbc-time-column .rbc-timeslot-wrapper .menu,
.rbc-calendar .rbc-time-gutter.rbc-time-column .rbc-timeslot-wrapper .rbc-timeslot-create {
  display: none;
}
.rbc-calendar .rbc-allday-cell {
  display: none;
}
.rbc-calendar .rbc-current-time-indicator {
  background-color: #f8c81c;
  width: 700%;
}
.rbc-calendar .rbc-timeslot-group {
  min-height: 77px;
}
.rbc-calendar .rbc-timeslot-group .rbc-label {
  display: block;
  background-color: #222629;
  font-size: 11px;
  padding: 0 7.5px;
}
.rbc-calendar .rbc-timeslot-group:not(:first-child) .rbc-label {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}
.rbc-calendar .rbc-time-header-content {
  border-left: 0;
}
.rbc-calendar .rbc-header,
.rbc-calendar .rbc-header + .rbc-header {
  border-color: #41494f;
}
.rbc-calendar .rbc-header:first-child {
  border-left: 1px solid transparent;
}
.rbc-calendar .rbc-header + .rbc-header {
  border-image: linear-gradient(to top, #41494f, #222629) 1 1%;
}
.rbc-calendar .rbc-header {
  padding: 1rem;
  text-align: left;
}
.rbc-calendar .rbc-header button {
  text-transform: uppercase;
  letter-spacing: 0.46px;
}
.rbc-calendar .rbc-header button strong {
  display: block;
  text-align: left;
  color: white;
  margin-top: 0.2rem;
  font-size: 1.5rem;
}
.rbc-calendar .rbc-time-header.rbc-overflowing {
  border-right: 0;
}
.rbc-calendar .rbc-day-slot.rbc-time-column:nth-child(2) .rbc-events-container,
.rbc-calendar .rbc-day-slot.rbc-time-column:nth-child(2) .rbc-timeslot-group {
  border-left: 0;
}
.rbc-calendar .rbc-day-slot .rbc-events-container {
  margin-right: 0;
}
.rbc-calendar .rbc-day-slot .rbc-time-slot {
  border-top: 0;
}
.rbc-calendar .rbc-time-view {
  height: 100%;
  border: none;
}
.rbc-calendar .rbc-time-content {
  border-top: 0;
  overflow-x: hidden;
}
.rbc-calendar .rbc-timeslot-group {
  border-bottom: 1px solid rgba(65, 73, 79, 0.3);
  position: relative;
}
.rbc-calendar .rbc-time-content > * + * > * {
  border-left: 1px solid #41494f;
}
.rbc-calendar .rbc-today {
  background-color: rgba(248, 200, 28, 0.05);
}
.rbc-calendar .rbc-today.rbc-header * {
  color: #f8c81c;
}
.rbc-calendar .rbc-day-slot .rbc-event {
  border: 0;
  border-left: 4px solid;
  z-index: 2;
}
.rbc-calendar .rbc-event-wrapper .rbc-event,
.rbc-calendar .rbc-event.rbc-addons-dnd-drag-preview {
  padding: 0.5rem;
  gap: 0.2rem;
}
.rbc-calendar .rbc-event-wrapper .rbc-event strong,
.rbc-calendar .rbc-event.rbc-addons-dnd-drag-preview strong {
  display: block;
  margin-bottom: 0.2rem;
}
.rbc-calendar .rbc-event-wrapper .rbc-event *,
.rbc-calendar .rbc-event.rbc-addons-dnd-drag-preview * {
  font-size: 12px;
}
.rbc-calendar .rbc-event-wrapper .rbc-event-label {
  margin-bottom: 0.2rem;
}
.rbc-calendar .rbc-event-wrapper .rbc-event-content svg {
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
}
.rbc-calendar .rbc-event-wrapper .rbc-event-content svg path {
  fill: currentColor;
}
.rbc-calendar.rbc-addons-dnd-is-dragging .rbc-event-wrapper.dragging .rbc-event {
  opacity: 0 !important;
}
.rbc-calendar .rbc-event-wrapper.workout .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.workout .rbc-event.rbc-addons-dnd-drag-preview {
  background-color: rgba(248, 200, 28, 0.3);
  border-color: #f8c81c;
  color: #f8c81c;
}
.rbc-calendar .rbc-event-wrapper.competition .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.competition .rbc-event.rbc-addons-dnd-drag-preview {
  background-color: rgba(72, 193, 24, 0.3);
  border-color: #48c118;
  color: #48c118;
}
.rbc-calendar .rbc-event-wrapper.training .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.training .rbc-event.rbc-addons-dnd-drag-preview {
  background-color: rgba(33, 150, 243, 0.3);
  border-color: #2196f3;
  color: #2196f3;
}
.rbc-calendar .rbc-event-wrapper.meal .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.meal .rbc-event.rbc-addons-dnd-drag-preview {
  background-color: rgba(255, 127, 47, 0.3);
  border-color: #ff7f2f;
  color: #ff7f2f;
}
.rbc-calendar .rbc-event-wrapper.meeting .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.meeting .rbc-event.rbc-addons-dnd-drag-preview {
  background-color: rgba(244, 138, 185, 0.3);
  border-color: #f48ab9;
  color: #f48ab9;
}
.rbc-calendar .rbc-event-wrapper.meeting2 .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.meeting2 .rbc-event.rbc-addons-dnd-drag-preview {
  background-color: rgba(171, 138, 244, 0.3);
  border-color: #ab8af4;
  color: #ab8af4;
}
.rbc-calendar .rbc-event-wrapper.travel .rbc-event,
.rbc-calendar.rbc-addons-dnd-is-dragging.travel .rbc-event.rbc-addons-dnd-drag-preview {
  background-color: rgba(138, 150, 160, 0.3);
  border-color: #8a96a0;
  color: #8a96a0;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.App_container__3vylO {
  padding: 0 30px 30px;
}
.App_containerAll__1XT7n {
  padding: 30px;
}
.App_containerTb__3x-BI {
  padding: 30px 0;
}
.App_containerLr__1BXEn {
  margin: 0 30px;
}
.App_containerRbl__3STJF {
  margin: 0 30px 30px;
}
.App_textCenter__3XcXx {
  text-align: center;
}
.App_flex__3wvkE {
  display: flex;
}
.App_well__2i1S6 {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.App_well__2i1S6:not(:last-child) {
  margin-bottom: 10px;
}
.App_white__1GPTb {
  color: #fff;
}
.App_error__2gI36 {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.App_disabled__2O_Po {
  opacity: 0.5;
  pointer-events: none;
}
.App_graph__1tMYM {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.App_graphHeader__2eFlm,
.App_graph-header__1Hb9i {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.App_buttonGroup__2B8do {
  display: flex;
}
.App_buttonGroup__2B8do button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.App_center__rCXqG {
  align-items: center;
  justify-content: center;
}
.App_bodyMapDot__1Bz2u {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.App_good__3uXnc {
  color: #26d87c;
}
.App_bad__1tL0- {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes App_spin__3pyL8 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes App_spinCentre__2GUmQ {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
body.fixedHeight,
body.fixedHeight #root,
body.fixedHeight #root main {
  height: 100%;
  overflow: hidden;
  /* safari x scroll */
}
body.smallSidebar .App_sidebar__3L02w {
  -webkit-transform: translateX(calc(-100% +  80px));
  transform: translateX(calc(-100% +  80px));
}
body.smallSidebar .App_nav__3eVQ4 {
  -webkit-transform: translateX(calc(100% -  80px - 0.5rem));
  transform: translateX(calc(100% -  80px - 0.5rem));
}
body.smallSidebar .App_nav__3eVQ4 a span {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}
body.smallSidebar .App_version__1QZa5 {
  display: none;
}
body.smallSidebar .App_logout__1V-mm {
  -webkit-transform: translateX(calc(100% - 80px - 30px / 2));
  transform: translateX(calc(100% - 80px - 30px / 2));
}
body.smallSidebar .App_logout__1V-mm span {
  -webkit-transform: translateX(30px);
  transform: translateX(30px);
}
body.smallSidebar .App_logout__1V-mm svg {
  -webkit-transform: translateX(15px);
  transform: translateX(15px);
}
body.smallSidebar .App_body__11wcs {
  margin-left: 80px;
}
.App_sidebar__3L02w {
  position: fixed;
  z-index: 20;
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 100vh;
  background-color: #1a1d1f;
  overflow: auto;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
@media (max-width: 1367px) {
  .App_sidebar__3L02w {
    -webkit-transform: translateX(calc(-100% +  80px));
    transform: translateX(calc(-100% +  80px));
  }
}
@media print {
  .App_sidebar__3L02w {
    display: none;
    transition: none;
  }
}
.App_sidebar__3L02w.App_super__3HH2Y {
  background-color: #41494f;
  border-right-color: #8a96a0;
}
.App_logo__j7m6G {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100px + 30px*2);
  padding: 30px;
}
.App_logo__j7m6G img {
  max-width: 80px;
  height: auto;
}
.App_nav__3eVQ4 {
  flex-grow: 1;
  padding: 0 1rem 1rem;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
@media (max-width: 1367px) {
  .App_nav__3eVQ4 {
    -webkit-transform: translateX(calc(100% -  80px - 0.5rem));
    transform: translateX(calc(100% -  80px - 0.5rem));
  }
}
.App_nav__3eVQ4 a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  margin: 0 0 10px;
  padding: 1.1rem;
  color: #8a96a0;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 20px;
  transition: color 0.15s ease, background-color 0.15s ease;
}
.App_nav__3eVQ4 a svg {
  width: 20px;
  vertical-align: middle;
  margin-right: 15px;
}
.App_nav__3eVQ4 a svg line,
.App_nav__3eVQ4 a svg path,
.App_nav__3eVQ4 a svg polyline,
.App_nav__3eVQ4 a svg polygon,
.App_nav__3eVQ4 a svg rect {
  stroke: #8a96a0;
  transition: stroke 0.15s ease;
}
.App_nav__3eVQ4 a span {
  flex-grow: 1;
  color: inherit;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
@media (max-width: 1367px) {
  .App_nav__3eVQ4 a span {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
}
.App_nav__3eVQ4 a i {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #f8c81c;
  border-radius: 50%;
}
.App_nav__3eVQ4 a.App_active__1yn8t,
.App_nav__3eVQ4 a:hover {
  color: #fff;
}
.App_nav__3eVQ4 a.App_active__1yn8t svg line,
.App_nav__3eVQ4 a:hover svg line,
.App_nav__3eVQ4 a.App_active__1yn8t svg path,
.App_nav__3eVQ4 a:hover svg path,
.App_nav__3eVQ4 a.App_active__1yn8t svg polyline,
.App_nav__3eVQ4 a:hover svg polyline,
.App_nav__3eVQ4 a.App_active__1yn8t svg polygon,
.App_nav__3eVQ4 a:hover svg polygon,
.App_nav__3eVQ4 a.App_active__1yn8t svg rect,
.App_nav__3eVQ4 a:hover svg rect {
  stroke: #fff;
}
.App_nav__3eVQ4 a.App_active__1yn8t {
  background-color: #485056;
}
.App_version__1QZa5 {
  padding: 15px 30px;
  color: rgba(138, 150, 160, 0.5);
  font-size: 11px;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.App_version__1QZa5 button {
  padding: 0;
  margin: 0 0 0 10px;
  color: red;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
}
@media (max-width: 1367px) {
  .App_version__1QZa5 {
    display: none;
  }
}
.App_logout__1V-mm {
  padding: 30px;
  color: #8a96a0;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  text-align: left;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-top: 1px solid #41494f;
  cursor: pointer;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
@media (max-width: 1367px) {
  .App_logout__1V-mm {
    -webkit-transform: translateX(calc(100% - 80px - 30px / 2));
    transform: translateX(calc(100% - 80px - 30px / 2));
  }
}
.App_super__3HH2Y .App_logout__1V-mm {
  border-top-color: #485056;
}
.App_logout__1V-mm span {
  display: inline-block;
  transition: color 0.15s ease, -webkit-transform 0.5s ease;
  transition: color 0.15s ease, transform 0.5s ease;
  transition: color 0.15s ease, transform 0.5s ease, -webkit-transform 0.5s ease;
}
@media (max-width: 1367px) {
  .App_logout__1V-mm span {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}
.App_logout__1V-mm svg {
  width: 20px;
  vertical-align: middle;
  margin-right: 15px;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.App_logout__1V-mm svg line,
.App_logout__1V-mm svg path,
.App_logout__1V-mm svg polyline,
.App_logout__1V-mm svg polygon,
.App_logout__1V-mm svg rect {
  stroke: #8a96a0;
  transition: stroke 0.15s ease;
}
@media (max-width: 1367px) {
  .App_logout__1V-mm svg {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
}
.App_logout__1V-mm.App_active__1yn8t span,
.App_logout__1V-mm:hover span {
  color: #fff;
}
.App_logout__1V-mm.App_active__1yn8t svg line,
.App_logout__1V-mm:hover svg line,
.App_logout__1V-mm.App_active__1yn8t svg path,
.App_logout__1V-mm:hover svg path,
.App_logout__1V-mm.App_active__1yn8t svg polyline,
.App_logout__1V-mm:hover svg polyline,
.App_logout__1V-mm.App_active__1yn8t svg polygon,
.App_logout__1V-mm:hover svg polygon,
.App_logout__1V-mm.App_active__1yn8t svg rect,
.App_logout__1V-mm:hover svg rect {
  stroke: #fff;
}
.App_body__11wcs {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-left: 240px;
  transition: margin-left 0.5s ease;
}
@media print {
  .App_body__11wcs {
    margin-left: 0 !important;
    transition: none;
  }
}
@media (max-width: 1367px) {
  .App_body__11wcs {
    margin-left: 80px;
  }
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Notifications_container__3ceC7 {
  padding: 0 30px 30px;
}
.Notifications_containerAll__3FES8 {
  padding: 30px;
}
.Notifications_containerTb__34iSy {
  padding: 30px 0;
}
.Notifications_containerLr__1FB1n {
  margin: 0 30px;
}
.Notifications_containerRbl__3YgP1 {
  margin: 0 30px 30px;
}
.Notifications_textCenter__2qP9B {
  text-align: center;
}
.Notifications_flex__3PGTk {
  display: flex;
}
.Notifications_well__29XUv {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.Notifications_well__29XUv:not(:last-child) {
  margin-bottom: 10px;
}
.Notifications_white__2HzFa {
  color: #fff;
}
.Notifications_error__3KtCt {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.Notifications_disabled__6mC3g {
  opacity: 0.5;
  pointer-events: none;
}
.Notifications_graph__dIJrW {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.Notifications_graphHeader__3wZyM,
.Notifications_graph-header__2gZX0 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.Notifications_buttonGroup__gmhnw {
  display: flex;
}
.Notifications_buttonGroup__gmhnw button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Notifications_center__2vJwK {
  align-items: center;
  justify-content: center;
}
.Notifications_bodyMapDot__3WoSt {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.Notifications_good__11f4P {
  color: #26d87c;
}
.Notifications_bad__31cy5 {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes Notifications_spin__3B53V {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Notifications_spinCentre__1JqN0 {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Notifications_list__1YQPq {
  position: fixed;
  z-index: 30;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 380px;
  list-style: none;
  pointer-events: none;
}
.Notifications_li__3GyNz {
  display: block;
  padding: 10px 10px 0;
  width: 100%;
  overflow: visible;
}
.Notifications_notification__r7hZT {
  display: flex;
  align-items: center;
  padding: 20px;
  color: #8a96a0;
  font-size: 12px;
  line-height: 1.5em;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 5px;
  pointer-events: all;
}
.Notifications_notification__r7hZT > div {
  padding-left: 20px;
  flex-shrink: 9999;
}
.Notifications_notification__r7hZT strong {
  display: block;
  margin-bottom: 2px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Auth_container__34wud {
  padding: 0 30px 30px;
}
.Auth_containerAll__nnnMP {
  padding: 30px;
}
.Auth_containerTb__1PlS4 {
  padding: 30px 0;
}
.Auth_containerLr__2R7vF {
  margin: 0 30px;
}
.Auth_containerRbl__2RVxm {
  margin: 0 30px 30px;
}
.Auth_textCenter__2ZqJ6 {
  text-align: center;
}
.Auth_flex__3HRWm {
  display: flex;
}
.Auth_well__1uptm {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.Auth_well__1uptm:not(:last-child) {
  margin-bottom: 10px;
}
.Auth_white__1l9lP {
  color: #fff;
}
.Auth_error__2tHej {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.Auth_disabled__24fpY {
  opacity: 0.5;
  pointer-events: none;
}
.Auth_graph__1fE_D {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.Auth_graphHeader__3_307,
.Auth_graph-header__X1nC6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.Auth_buttonGroup__Hm9-7 {
  display: flex;
}
.Auth_buttonGroup__Hm9-7 button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Auth_center__3Gt1H {
  align-items: center;
  justify-content: center;
}
.Auth_bodyMapDot__V7-q_ {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.Auth_good__ja7CR {
  color: #26d87c;
}
.Auth_bad__wzOrj {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes Auth_spin__nByh2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Auth_spinCentre__30RZf {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Auth_wrap__2FKPs {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
}
@media (max-width: 1000px) {
  .Auth_wrap__2FKPs {
    flex-direction: column-reverse;
    height: auto;
    min-height: 100vh;
    font-size: 16px;
  }
  .Auth_wrap__2FKPs h3 {
    font-size: 30px;
    line-height: 1.25em;
  }
}
.Auth_form__37WkD {
  position: relative;
  z-index: 2;
  width: 50vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  background-color: #222629;
  -webkit-transform: translateX(-50vw);
  transform: translateX(-50vw);
  transition: -webkit-transform 0.75s ease;
  transition: transform 0.75s ease;
  transition: transform 0.75s ease, -webkit-transform 0.75s ease;
}
@media (max-width: 1000px) {
  .Auth_form__37WkD {
    width: 100vw;
    height: auto;
    min-height: calc(100vh - 200px);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.Auth_validSlug__1BJZV .Auth_form__37WkD {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.Auth_form__37WkD h3 {
  width: 100%;
  margin-bottom: 30px;
}
.Auth_form__37WkD h3 + p {
  margin-top: -15px;
  margin-bottom: 30px;
}
.Auth_form__37WkD form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 30px;
  width: 100%;
}
@media (max-width: 1000px) {
  .Auth_form__37WkD form {
    align-items: flex-start;
    margin-top: -67.5px;
  }
}
.Auth_form__37WkD footer {
  font-size: 12px;
}
.Auth_form__37WkD footer span {
  display: inline-block;
  padding: 0 10px;
}
.Auth_form__37WkD footer a {
  color: #8a96a0;
  text-decoration: none;
}
.Auth_form__37WkD footer a:hover {
  text-decoration: underline;
}
.Auth_form-wrap__YTOgj {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}
.Auth_form-wrap__YTOgj > *:not(:last-child, svg) {
  margin-bottom: 1.875rem;
}
.Auth_form-wrap__YTOgj > div {
  width: 100%;
}
.Auth_form-wrap__YTOgj > svg {
  margin-bottom: 60px;
}
.Auth_form-wrap__YTOgj label {
  width: 100%;
}
.Auth_form-wrap__YTOgj button {
  width: 100%;
  margin: 0 0 30px;
}
.Auth_notice__2rwlF {
  width: 100%;
  margin: 0 0 30px;
}
.Auth_img__32xB0 {
  position: relative;
  z-index: 1;
  width: 50vw;
  height: 100vh;
}
@media (max-width: 1000px) {
  .Auth_img__32xB0 {
    width: 100vw;
    height: 200px;
  }
}
.Auth_img__32xB0 img {
  width: 100vw;
  height: 100%;
  opacity: 0.3 !important;
  object-fit: cover;
  pointer-events: none;
  -webkit-transform: translateX(-50vw);
  transform: translateX(-50vw);
  transition: -webkit-transform 0.75s ease !important;
  transition: transform 0.75s ease !important;
  transition: transform 0.75s ease, -webkit-transform 0.75s ease !important;
}
.Auth_validSlug__1BJZV .Auth_img__32xB0 img {
  -webkit-transform: translateX(-25vw);
  transform: translateX(-25vw);
}
@media (max-width: 1000px) {
  .Auth_img__32xB0 img {
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
  }
}
.Auth_input__jQ0Pe {
  width: 100%;
  margin-bottom: 15px;
  padding: 0 0 0 14px;
  border: 1px solid #41494f;
  border-radius: 4px;
}
.Auth_why__2E0Ia {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Auth_why__2E0Ia button {
  width: auto;
  margin: 0;
  opacity: 0.25;
}
.Auth_why__2E0Ia button span {
  text-decoration: none;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.NewsFeed_container__3bkaP {
  padding: 0 30px 30px;
}
.NewsFeed_containerAll__g0G2h {
  padding: 30px;
}
.NewsFeed_containerTb__3UYq0 {
  padding: 30px 0;
}
.NewsFeed_containerLr__3nDhN {
  margin: 0 30px;
}
.NewsFeed_containerRbl__RtSBR {
  margin: 0 30px 30px;
}
.NewsFeed_textCenter__2uUkg {
  text-align: center;
}
.NewsFeed_flex__3zEsc {
  display: flex;
}
.NewsFeed_well__RAGqL {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.NewsFeed_well__RAGqL:not(:last-child) {
  margin-bottom: 10px;
}
.NewsFeed_white__1WaMO {
  color: #fff;
}
.NewsFeed_error__ezmrA {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.NewsFeed_disabled__k3mqc {
  opacity: 0.5;
  pointer-events: none;
}
.NewsFeed_graph__rm1AQ {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.NewsFeed_graphHeader__2Ynwc,
.NewsFeed_graph-header__2_C0Z {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.NewsFeed_buttonGroup__2S0Pg {
  display: flex;
}
.NewsFeed_buttonGroup__2S0Pg button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.NewsFeed_center__2NbGD {
  align-items: center;
  justify-content: center;
}
.NewsFeed_bodyMapDot__2JQx0 {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.NewsFeed_good__2GbAx {
  color: #26d87c;
}
.NewsFeed_bad__1U32f {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes NewsFeed_spin__16Bmz {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes NewsFeed_spinCentre__1dp-d {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.smallSidebar .NewsFeed_feed-btn__3WwQF {
  -webkit-transform: translate3d(65px, -50%, 0);
  transform: translate3d(65px, -50%, 0);
}
.NewsFeed_feed-btn__3WwQF {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: translate3d(68px, -50%, 0);
  transform: translate3d(68px, -50%, 0);
}
.NewsFeed_feed-btn__3WwQF svg {
  pointer-events: none;
}
@media (max-width: 1367px) {
  .NewsFeed_feed-btn__3WwQF {
    -webkit-transform: translate3d(65px, -50%, 0);
    transform: translate3d(65px, -50%, 0);
  }
}
.NewsFeed_overlay__3rV94 {
  position: fixed;
  z-index: 25;
  top: 0;
  left: 240px;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 38, 41, 0.8);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s ease;
}
@media (max-width: 1367px) {
  .NewsFeed_overlay__3rV94 {
    left: 80px;
  }
}
.NewsFeed_overlayOpen__3uvYd {
  opacity: 1;
}
.NewsFeed_overlayClose__1JLBz {
  opacity: 0;
  transition-delay: 0.1s;
}
.NewsFeed_modal__3Mn6u {
  width: 100%;
  max-width: 650px;
  height: 100vh;
  inset: unset;
  padding: 30px;
  background-color: #222629;
  border: none;
  border-right: 1px solid #41494f;
  border-radius: 0;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  opacity: 1;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  overflow: auto;
}
.NewsFeed_modal__3Mn6u h3 {
  margin-bottom: 30px;
}
.NewsFeed_modalOpen__2NfrL {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.NewsFeed_modalClose__1Kx0b {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.NewsFeedItem_container__3CT4p {
  padding: 0 30px 30px;
}
.NewsFeedItem_containerAll__1tU8t {
  padding: 30px;
}
.NewsFeedItem_containerTb__TNiDp {
  padding: 30px 0;
}
.NewsFeedItem_containerLr__1FKUi {
  margin: 0 30px;
}
.NewsFeedItem_containerRbl__2h1rt {
  margin: 0 30px 30px;
}
.NewsFeedItem_textCenter__29DWg {
  text-align: center;
}
.NewsFeedItem_flex__2loUO {
  display: flex;
}
.NewsFeedItem_well__3XRxg {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.NewsFeedItem_well__3XRxg:not(:last-child) {
  margin-bottom: 10px;
}
.NewsFeedItem_white__3MEBV {
  color: #fff;
}
.NewsFeedItem_error__3HQJf {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.NewsFeedItem_disabled__2nh10 {
  opacity: 0.5;
  pointer-events: none;
}
.NewsFeedItem_graph__1SZIg {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.NewsFeedItem_graphHeader__Z1Nj-,
.NewsFeedItem_graph-header__3ASiQ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.NewsFeedItem_buttonGroup__3hZoz {
  display: flex;
}
.NewsFeedItem_buttonGroup__3hZoz button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.NewsFeedItem_center__NoD5W {
  align-items: center;
  justify-content: center;
}
.NewsFeedItem_bodyMapDot__1NOYZ {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.NewsFeedItem_good__YDwGA {
  color: #26d87c;
}
.NewsFeedItem_bad__1wXQn {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes NewsFeedItem_spin__1KwlQ {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes NewsFeedItem_spinCentre__28Re8 {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.NewsFeedItem_item__3dpr_ {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
  transition: border-color 10s ease;
}
.NewsFeedItem_item__3dpr_:last-child {
  margin-bottom: 30px;
}
.NewsFeedItem_item__3dpr_.NewsFeedItem_unread__1OKZv {
  border-color: #f8c81c;
}
.NewsFeedItem_item__3dpr_ img {
  width: 40px;
  height: 40px;
  vertical-align: middle;
  margin-right: 15px;
  border-radius: 2px;
}
.NewsFeedItem_item__3dpr_ a {
  font-weight: bold;
  text-decoration: none;
}
.NewsFeedItem_item__3dpr_ em {
  font-style: normal;
  color: #fff;
}
.NewsFeedItem_item__3dpr_ p {
  flex-grow: 1;
  margin: 0 0 3px !important;
  line-height: 1.2em;
}
.NewsFeedItem_item__3dpr_ time {
  color: #8a96a0;
  font-size: 11px;
  font-weight: bold;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Avatar_container__2kkmd {
  padding: 0 30px 30px;
}
.Avatar_containerAll__IK4uZ {
  padding: 30px;
}
.Avatar_containerTb__2A0Vn {
  padding: 30px 0;
}
.Avatar_containerLr__300Ia {
  margin: 0 30px;
}
.Avatar_containerRbl__2gMk0 {
  margin: 0 30px 30px;
}
.Avatar_textCenter__2KRS5 {
  text-align: center;
}
.Avatar_flex__3DzOV {
  display: flex;
}
.Avatar_well__1Bvdp {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.Avatar_well__1Bvdp:not(:last-child) {
  margin-bottom: 10px;
}
.Avatar_white__1TlNA {
  color: #fff;
}
.Avatar_error__3KBZJ {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.Avatar_disabled__3VhKp {
  opacity: 0.5;
  pointer-events: none;
}
.Avatar_graph__1E8Fi {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.Avatar_graphHeader__3145T,
.Avatar_graph-header__2JEkb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.Avatar_buttonGroup__3vdDX {
  display: flex;
}
.Avatar_buttonGroup__3vdDX button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Avatar_center__37tn1 {
  align-items: center;
  justify-content: center;
}
.Avatar_bodyMapDot__2cu-j {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.Avatar_good__3Pkxh {
  color: #26d87c;
}
.Avatar_bad__2rVMz {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes Avatar_spin__3NDYl {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Avatar_spinCentre__3F7_Z {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Avatar_small__3mt91 {
  width: 36px;
  height: 36px;
}
.Avatar_round__3dEWk {
  border-radius: 50%;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Spinner_container__Jq1J4 {
  padding: 0 30px 30px;
}
.Spinner_containerAll__1fzYc {
  padding: 30px;
}
.Spinner_containerTb__336EP {
  padding: 30px 0;
}
.Spinner_containerLr__30_QD {
  margin: 0 30px;
}
.Spinner_containerRbl__12j11 {
  margin: 0 30px 30px;
}
.Spinner_textCenter__2SqX0 {
  text-align: center;
}
.Spinner_flex__7rSTs {
  display: flex;
}
.Spinner_well__nFq0k {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.Spinner_well__nFq0k:not(:last-child) {
  margin-bottom: 10px;
}
.Spinner_white__3y6w2 {
  color: #fff;
}
.Spinner_error__2H9EP {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.Spinner_disabled__1I2Hh {
  opacity: 0.5;
  pointer-events: none;
}
.Spinner_graph__1QECo {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.Spinner_graphHeader__GtE_F,
.Spinner_graph-header__cnMVJ {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.Spinner_buttonGroup__32JOv {
  display: flex;
}
.Spinner_buttonGroup__32JOv button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Spinner_center__1SEK9 {
  align-items: center;
  justify-content: center;
}
.Spinner_bodyMapDot__zN2Yr {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.Spinner_good__2RuNC {
  color: #26d87c;
}
.Spinner_bad__2d3EY {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes Spinner_spin__2MSEP {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Spinner_spinCentre__2zxf6 {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Spinner_spinner__3l-ic {
  display: inline-block;
  border: 3px solid #8a96a0;
  border-top-color: transparent;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s ease;
  -webkit-animation: Spinner_spin__2MSEP 1s linear infinite;
  animation: Spinner_spin__2MSEP 1s linear infinite;
}
.Spinner_centre__k4Vv- {
  -webkit-animation: Spinner_spinCentre__2zxf6 1s linear infinite;
  animation: Spinner_spinCentre__2zxf6 1s linear infinite;
}
.Spinner_show__3teeU {
  opacity: 1;
  transition-delay: 0.75s;
}
.Spinner_small__WJOxV {
  width: 22.5px;
  height: 22.5px;
}
.Spinner_medium__1HmC- {
  width: 45px;
  height: 45px;
}
.Spinner_large__2OQs3 {
  width: 60px;
  height: 60px;
}
.Spinner_block__2Q0dq {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}
.Spinner_noStretch__3zy3d {
  height: auto;
  margin-top: 30px;
}
.Spinner_fixed__14zrd {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.ErrorList_container__2VsMN {
  padding: 0 30px 30px;
}
.ErrorList_containerAll__2f8Wr {
  padding: 30px;
}
.ErrorList_containerTb__2Cz-W {
  padding: 30px 0;
}
.ErrorList_containerLr__3oREk {
  margin: 0 30px;
}
.ErrorList_containerRbl__2YXJf {
  margin: 0 30px 30px;
}
.ErrorList_textCenter__1lBpy {
  text-align: center;
}
.ErrorList_flex__2e2gz {
  display: flex;
}
.ErrorList_well__3Mg2N {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.ErrorList_well__3Mg2N:not(:last-child) {
  margin-bottom: 10px;
}
.ErrorList_white__1d9a4 {
  color: #fff;
}
.ErrorList_error__27y_i {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.ErrorList_disabled__3EwnG {
  opacity: 0.5;
  pointer-events: none;
}
.ErrorList_graph__3wk2c {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.ErrorList_graphHeader__3-YDZ,
.ErrorList_graph-header__3DFhA {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.ErrorList_buttonGroup__D_lu2 {
  display: flex;
}
.ErrorList_buttonGroup__D_lu2 button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.ErrorList_center__2_KYG {
  align-items: center;
  justify-content: center;
}
.ErrorList_bodyMapDot__2c6h5 {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.ErrorList_good__2bsjE {
  color: #26d87c;
}
.ErrorList_bad__2b3ZE {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes ErrorList_spin__2qgxL {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes ErrorList_spinCentre__2Qrr3 {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.ErrorList_errorList__2LyKc {
  display: block;
  padding: 15px;
  list-style: none;
  color: #ee1737;
  line-height: 1.5em;
  background-color: rgba(238, 23, 55, 0.05);
  border: 1px solid #c40e29;
  border-radius: 5px;
}
.ErrorList_errorList__2LyKc:not(:last-child) {
  margin-bottom: 30px;
}
.ErrorList_errorList__2LyKc li:not(:last-child) {
  margin-bottom: 10px;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Divider_container__3O9GS {
  padding: 0 30px 30px;
}
.Divider_containerAll__2V35k {
  padding: 30px;
}
.Divider_containerTb__-SUCb {
  padding: 30px 0;
}
.Divider_containerLr__3wGMK {
  margin: 0 30px;
}
.Divider_containerRbl__3gdzD {
  margin: 0 30px 30px;
}
.Divider_textCenter__11o7v {
  text-align: center;
}
.Divider_flex__17kTW {
  display: flex;
}
.Divider_well__3vsws {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.Divider_well__3vsws:not(:last-child) {
  margin-bottom: 10px;
}
.Divider_white__2TFim {
  color: #fff;
}
.Divider_error__1edtc {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.Divider_disabled__CJPwT {
  opacity: 0.5;
  pointer-events: none;
}
.Divider_graph__22BbP {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.Divider_graphHeader__1B4Sg,
.Divider_graph-header__2huB4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.Divider_buttonGroup__2Uc_Q {
  display: flex;
}
.Divider_buttonGroup__2Uc_Q button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Divider_center__1UYxI {
  align-items: center;
  justify-content: center;
}
.Divider_bodyMapDot__1qTb2 {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.Divider_good__1Rrz1 {
  color: #26d87c;
}
.Divider_bad__2DNaY {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes Divider_spin__1CvrL {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Divider_spinCentre__1Ri35 {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Divider_divider__1KGP4 {
  display: flex;
  align-items: center;
  margin: 30px 0;
  color: #8a96a0;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0;
}
.Divider_divider__1KGP4:after {
  content: '';
  display: block;
  flex-grow: 1;
  height: 1px;
  margin-left: 10px;
  background-color: #41494f;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.styles_container__12lV_ {
  padding: 0 30px 30px;
}
.styles_containerAll__nsFdd {
  padding: 30px;
}
.styles_containerTb__MhG9Q {
  padding: 30px 0;
}
.styles_containerLr__BPLo4 {
  margin: 0 30px;
}
.styles_containerRbl__1xvfO {
  margin: 0 30px 30px;
}
.styles_textCenter__2wR-I {
  text-align: center;
}
.styles_flex__3jcMY {
  display: flex;
}
.styles_well__oxPY3 {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.styles_well__oxPY3:not(:last-child) {
  margin-bottom: 10px;
}
.styles_white__3Ea_D {
  color: #fff;
}
.styles_error__2Ib9U {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.styles_disabled__2k12s {
  opacity: 0.5;
  pointer-events: none;
}
.styles_graph__3_ISR {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.styles_graphHeader__2PvWq,
.styles_graph-header__xpUPM {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.styles_buttonGroup__3ndKE {
  display: flex;
}
.styles_buttonGroup__3ndKE button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.styles_center__1gPlq {
  align-items: center;
  justify-content: center;
}
.styles_bodyMapDot__35Rlc {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.styles_good__1861m {
  color: #26d87c;
}
.styles_bad__30lj7 {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes styles_spin__110jb {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes styles_spinCentre__113gU {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.styles_switchBtn__326qX {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: translate3d(-100px, -50%, 0);
  transform: translate3d(-100px, -50%, 0);
}
.styles_switchBtn__326qX svg {
  pointer-events: none;
}
@media (max-width: 1367px) {
  .styles_switchBtn__326qX {
    -webkit-transform: translate3d(95px, -175%, 0);
    transform: translate3d(95px, -175%, 0);
  }
}
.styles_overlay__38R09 {
  position: fixed;
  z-index: 25;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(26, 29, 31, 0.8);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.styles_overlayOpen__2v7UL {
  opacity: 1;
}
.styles_overlayClose__e_x1j {
  opacity: 0;
  transition-delay: 0.1s;
}
.styles_modal__LnOwE {
  width: 100%;
  max-width: 960px;
  max-height: 100vh;
  inset: unset;
  margin: 0 auto;
  padding: 100px 30px;
  background: none;
  border: none;
  opacity: 1;
  overflow: auto;
}
.styles_modal__LnOwE h3 {
  margin-bottom: 30px;
}
.styles_header__1TGnK {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}
.styles_header__1TGnK input {
  min-width: 50%;
  padding: 11px 0;
  color: #fff;
  text-indent: 16px;
  -webkit-appearance: none;
  appearance: none;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 8px;
  outline: none;
}
.styles_header__1TGnK input::-webkit-input-placeholder {
  color: rgba(138, 150, 160, 0.5);
}
.styles_header__1TGnK input::placeholder {
  color: rgba(138, 150, 160, 0.5);
}
.styles_tabs__1rBs9 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: 3px;
  background: #1a1d1f;
  border-radius: 8px;
}
.styles_tabs__1rBs9::before {
  content: '';
  position: absolute;
  z-index: 0;
  top: 3px;
  left: 3px;
  width: 115px;
  height: calc(100% - 6px);
  background: #8a96a0;
  border-radius: 4px;
  transition: -webkit-transform 0.15s ease;
  transition: transform 0.15s ease;
  transition: transform 0.15s ease, -webkit-transform 0.15s ease;
}
.styles_tabs__1rBs9.styles_flip__2-uaY::before {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.styles_tabs__1rBs9.styles_flip__2-uaY button:first-child {
  color: #8a96a0;
}
.styles_tabs__1rBs9.styles_flip__2-uaY button:last-child {
  color: #1a1d1f;
}
.styles_tabs__1rBs9 button {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 115px;
  padding: 10px 0;
  color: #8a96a0;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.15s ease;
}
.styles_tabs__1rBs9 button:first-child {
  color: #1a1d1f;
}
.styles_orgs__2lW01 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 50px;
  list-style: none;
}
.styles_orgs__2lW01 li button,
.styles_orgs__2lW01 li a {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #8a96a0;
  font-size: 13px;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
}
.styles_orgs__2lW01 li button span,
.styles_orgs__2lW01 li a span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  color: #f8c81c;
  font-size: 54px;
  font-weight: bold;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
}
.styles_orgs__2lW01 li button img,
.styles_orgs__2lW01 li a img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}
.styles_orgs__2lW01 li button svg,
.styles_orgs__2lW01 li a svg {
  vertical-align: middle;
}
.styles_orgs__2lW01 li button small,
.styles_orgs__2lW01 li a small {
  display: block;
  margin-top: 5px;
  font-size: 11px;
  opacity: 0.5;
}
.styles_current__3I7_R {
  opacity: 0.5;
  pointer-events: none;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.NoAccess_container__Dw6HQ {
  padding: 0 30px 30px;
}
.NoAccess_containerAll__3NSKX {
  padding: 30px;
}
.NoAccess_containerTb__C3d0U {
  padding: 30px 0;
}
.NoAccess_containerLr__2kGPL {
  margin: 0 30px;
}
.NoAccess_containerRbl__1VJV0 {
  margin: 0 30px 30px;
}
.NoAccess_textCenter__2bIOc {
  text-align: center;
}
.NoAccess_flex__1vvBO {
  display: flex;
}
.NoAccess_well__OdiE_ {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.NoAccess_well__OdiE_:not(:last-child) {
  margin-bottom: 10px;
}
.NoAccess_white__1xF5d {
  color: #fff;
}
.NoAccess_error__2JYrM {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.NoAccess_disabled__sPeh9 {
  opacity: 0.5;
  pointer-events: none;
}
.NoAccess_graph__1p_nj {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.NoAccess_graphHeader__Vw0EQ,
.NoAccess_graph-header__WDLPE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.NoAccess_buttonGroup__MHkug {
  display: flex;
}
.NoAccess_buttonGroup__MHkug button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.NoAccess_center__1GLyx {
  align-items: center;
  justify-content: center;
}
.NoAccess_bodyMapDot__14iUa {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.NoAccess_good__udItP {
  color: #26d87c;
}
.NoAccess_bad__PRttx {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes NoAccess_spin__2jyyH {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes NoAccess_spinCentre__2Dd8O {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.NoAccess_wrap__2P5zb {
  padding: 30px;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.ErrorScreen_container__baFNY {
  padding: 0 30px 30px;
}
.ErrorScreen_containerAll__2ZsZI {
  padding: 30px;
}
.ErrorScreen_containerTb__3txFk {
  padding: 30px 0;
}
.ErrorScreen_containerLr__1-Jzq {
  margin: 0 30px;
}
.ErrorScreen_containerRbl__1oCVn {
  margin: 0 30px 30px;
}
.ErrorScreen_textCenter__2PyEI {
  text-align: center;
}
.ErrorScreen_flex__2XPPm {
  display: flex;
}
.ErrorScreen_well__1t-dL {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.ErrorScreen_well__1t-dL:not(:last-child) {
  margin-bottom: 10px;
}
.ErrorScreen_white__369xq {
  color: #fff;
}
.ErrorScreen_error__2PS02 {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.ErrorScreen_disabled__18aUj {
  opacity: 0.5;
  pointer-events: none;
}
.ErrorScreen_graph__1tu3j {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.ErrorScreen_graphHeader__SFXud,
.ErrorScreen_graph-header__1sxIs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.ErrorScreen_buttonGroup__aMtOM {
  display: flex;
}
.ErrorScreen_buttonGroup__aMtOM button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.ErrorScreen_center__1dRm3 {
  align-items: center;
  justify-content: center;
}
.ErrorScreen_bodyMapDot__2A65N {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.ErrorScreen_good__1GVXd {
  color: #26d87c;
}
.ErrorScreen_bad__14cVP {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes ErrorScreen_spin__27wyF {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes ErrorScreen_spinCentre__3-W9z {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.ErrorScreen_errorScreen__2VpqJ {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  overflow: auto;
}
.ErrorScreen_errorScreen__2VpqJ:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(/static/media/quino.b7cb4007.jpg);
  background-size: cover;
  opacity: 0.1;
}
.ErrorScreen_errorScreen__2VpqJ > div {
  margin: auto;
  max-width: 600px;
}
.ErrorScreen_errorScreen__2VpqJ h1 {
  margin-bottom: 30px;
}
.ErrorScreen_errorScreen__2VpqJ .ErrorScreen_stack__1MPrM {
  padding: 15px;
  line-height: 1.5em;
  font-size: 11px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.ErrorScreen_errorScreen__2VpqJ .ErrorScreen_stack__1MPrM strong {
  margin-bottom: 10px;
}
.ErrorScreen_errorScreen__2VpqJ .ErrorScreen_stack__1MPrM code {
  display: block;
  padding: 0;
  margin: 0;
  font-family: 'Fira Code', monospace;
  text-overflow: ellipsis;
  background: none;
  border: none;
  overflow: hidden;
}
.ErrorScreen_errorScreen__2VpqJ.ErrorScreen_overlay__36QXV {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  background-color: rgba(26, 29, 31, 0.9);
}
.ErrorScreen_errorScreen__2VpqJ.ErrorScreen_overlay__36QXV:before {
  content: none;
}
.ErrorScreen_footer__T3lIz {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 0;
}
.ErrorScreen_footer__T3lIz button {
  margin-bottom: 0 !important;
}
.ErrorScreen_footer__T3lIz button:not(:last-child) {
  margin-right: 10px;
}

/* Colours
// ========================================================================= */
/*@success: #48C118;*/
/* Typography
// ========================================================================= */
/* z-Index
// ========================================================================= */
/* Sizing
// ========================================================================= */
/* Reusable Components
// ========================================================================= */
.Button_container__3bzUY {
  padding: 0 30px 30px;
}
.Button_containerAll__269KF {
  padding: 30px;
}
.Button_containerTb__2RaJ6 {
  padding: 30px 0;
}
.Button_containerLr__3hbJb {
  margin: 0 30px;
}
.Button_containerRbl__1wqho {
  margin: 0 30px 30px;
}
.Button_textCenter__1tUu9 {
  text-align: center;
}
.Button_flex__2tnYD {
  display: flex;
}
.Button_well__22Mzm {
  background-color: #1a1d1f;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.Button_well__22Mzm:not(:last-child) {
  margin-bottom: 10px;
}
.Button_white__1dy6a {
  color: #fff;
}
.Button_error__1u-_l {
  color: #ee1737;
  margin-bottom: 0.5rem;
}
.Button_disabled__2Mnp8 {
  opacity: 0.5;
  pointer-events: none;
}
.Button_graph__2dqDj {
  padding: 30px 30px 15px 0;
  border: 1px solid #41494f;
  border-radius: 5px;
}
.Button_graphHeader__3UdWO,
.Button_graph-header__3scQ7 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 30px 30px;
}
.Button_buttonGroup__3zMXH {
  display: flex;
}
.Button_buttonGroup__3zMXH button:not(:last-child) {
  margin: 0 5px 0 0 !important;
}
.Button_center__1j30B {
  align-items: center;
  justify-content: center;
}
.Button_bodyMapDot__3gaD6 {
  position: absolute;
  width: 20px;
  height: 20px;
  background: radial-gradient(50% 50% at 50% 50%, #ee1737 0%, rgba(238, 23, 55, 0.0001) 100%);
}
.Button_good__25PWW {
  color: #26d87c;
}
.Button_bad__1376W {
  color: #ee1737;
}
/* Animation
// ========================================================================= */
@keyframes Button_spin__3nMd4 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes Button_spinCentre__2ZiEA {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
/* Misc
// ========================================================================= */
.Button_button__h54IP {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.15s ease;
}
.Button_button__h54IP svg:first-child {
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 10px;
}
.Button_button__h54IP span {
  position: static;
  color: #222629 !important;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  transition: opacity 0.15s ease;
}
.Button_button__h54IP:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  border: 3px solid #f8c81c;
  border-top-color: transparent !important;
  border-radius: 50%;
  opacity: 0;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  transition: opacity 0.15s ease;
}
.Button_button__h54IP:hover {
  text-decoration: none;
  opacity: 0.5;
}
.Button_button__h54IP:hover span {
  text-decoration: none;
}
.Button_button__h54IP:disabled {
  opacity: 0.25;
  pointer-events: none;
}
.Button_primary__3ySpE {
  background-color: #fff;
}
.Button_primary__3ySpE:after {
  border-color: #222629;
}
.Button_secondary__2uNg4 {
  background-color: #8a96a0;
}
.Button_secondary__2uNg4:after {
  border-color: #fff;
}
.Button_tertiary__3Fngn {
  background-color: #1a1d1f;
}
.Button_tertiary__3Fngn span {
  color: #8a96a0 !important;
}
.Button_quaternary__2sWhG {
  background-color: #41494f;
}
.Button_quaternary__2sWhG:hover {
  opacity: 1 !important;
  background-color: #8a96a0;
}
.Button_brand__164e- {
  background-color: #f8c81c;
}
.Button_brand__164e-:after {
  border-color: #fff;
}
.Button_dashed__2Cmhv {
  background: none;
  border: 2px dashed #41494f;
  border-radius: 5px;
}
.Button_dashed__2Cmhv span {
  color: #41494f !important;
  font-size: 15px;
  font-weight: bold;
  text-transform: none;
}
.Button_dashed__2Cmhv:after {
  border-color: #41494f;
}
.Button_danger__1zyJp {
  background: rgba(238, 23, 55, 0.3);
  border: none;
  border-radius: 5px;
}
.Button_danger__1zyJp span {
  color: #ee1737 !important;
}
.Button_danger__1zyJp:after {
  border-color: #ee1737;
}
.Button_info__1y-BG {
  background: none;
  border: 1px solid #2196f3;
  border-radius: 5px;
}
.Button_info__1y-BG span {
  color: #2196f3 !important;
}
.Button_info__1y-BG:after {
  border-color: #2196f3;
}
.Button_success__3OgPE {
  background: #26d87c;
  border: 1px solid #26d87c;
  border-radius: 5px;
}
.Button_success__3OgPE span {
  color: #222629 !important;
}
.Button_success__3OgPE:after {
  border-color: #26d87c;
}
.Button_danger-small__1wKzR {
  background: none;
  border: none;
}
.Button_danger-small__1wKzR span {
  color: #ee1737 !important;
  font-size: 12px;
  text-transform: none;
}
.Button_danger-small__1wKzR:after {
  border-color: #ee1737;
}
.Button_none__2Rvvl {
  background: none;
  border-radius: 0;
}
.Button_text__1bds5 {
  padding: 0 !important;
  background: none;
  border: none;
  border-radius: 0;
}
.Button_text__1bds5 span {
  color: #fff !important;
  font-weight: normal;
  text-transform: none;
  text-decoration: underline !important;
}
.Button_outline__1AFgq {
  background: none;
  border: 1px solid #41494f;
}
.Button_outline__1AFgq span {
  color: #8a96a0 !important;
  font-weight: normal;
}
.Button_small__2ROCf {
  padding: 5px 10px 4px;
}
.Button_small__2ROCf:after {
  width: 8px;
  height: 8px;
}
.Button_small__2ROCf.Button_danger__1zyJp {
  padding: 4px 10px 3px;
}
.Button_small__2ROCf span {
  font-size: 13px;
}
.Button_large__2yXIM {
  padding: 11px 30px;
}
.Button_large__2yXIM.Button_danger__1zyJp {
  padding: 9px 15px;
}
.Button_huge__URuHp {
  width: 100%;
  padding: 20px;
}
.Button_huge__URuHp:after {
  width: 16px;
  height: 16px;
}
.Button_huge__URuHp.Button_danger__1zyJp {
  padding: 19px 20px;
}
.Button_wide__3wOu2 {
  width: 100%;
}
.Button_busy__xyf8l {
  pointer-events: none;
  tab-index: 0;
}
.Button_busy__xyf8l span {
  opacity: 0;
}
.Button_busy__xyf8l:after {
  opacity: 1;
  -webkit-animation: Button_spinCentre__2ZiEA 1s linear infinite;
  animation: Button_spinCentre__2ZiEA 1s linear infinite;
}

